.media-page-media-container
{
    position: relative;
    padding: 4rem 0 1rem;

    background-color: deepskyblue;
}

.media-page-media-block
{
    position: relative;
    /*top: 4rem;*/
    min-height: 100px;
    width: calc(100% - 2rem);
    max-width: calc(1300px - 2em - 17px);
    margin: 1rem auto;

    padding: 1rem;

    background-color: white;
}

.media-page-media
{
    border-style: solid;
    border-width: 0;
    border-radius: 1rem;
}

.media-page-background
{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}