.excerpt-card {
    --color: #f2d156;

    border-style: solid !important;
    border-width: 1px !important;
    border-color: var(--color) !important;
    border-radius: 2rem !important;

    box-shadow: 0 0 0 white !important;
}

.excerpt-card .ui.divider {
    background-color: #f2d156 !important;
    border-width: 0 !important;
    height: 1px !important;
}

.excerpt-card > .content > div.header {
    color: #bc3801 !important;
    font-family: "Pacifico", cursive !important;
}

.excerpt-card-button {
    margin-top: 1em !important;
}

.excerpt-card-modal {
    max-width: 1000px;

    --color: #f2d156;

    border-style: solid !important;
    border-width: 1px !important;
    border-color: var(--color) !important;
    border-radius: 2rem !important;

    box-shadow: 0 0 0 white;
}

.excerpt-card-modal > .icon {
    color: #bc3801 !important;
}

.excerpt-card-modal > .header {
    color: #8a2900 !important;
    font-family: "Pacifico", cursive !important;

    --color: #f2d156;

    border-style: solid !important;
    border-width: 2px 2px 1px 2px !important;
    border-color: var(--color) !important;
    border-radius: 2rem 2rem 0 0 !important;

    box-shadow: 0 0 0 white;
}

.excerpt-card-modal > .content {
    --color: #f2d156;

    border-style: solid !important;
    border-width: 0 2px 2px 2px !important;
    border-color: var(--color) !important;
    border-radius: 0 0 2rem 2rem !important;

    box-shadow: 0 0 0 white;
}