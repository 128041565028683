/* General */
.hero-section {
    position: relative;

    background-color: #fff0db;
}
@media (min-width: 590px) {
    .hero-section {
        min-height: 800px;
    }
}
@media (max-width: 589px) {
    .hero-section {
        height: calc(100vw * 1.6);
        max-height: 800px;
    }
}

.hero-section-padding {
    --header-height: 4rem;
    --vertical-padding: 0rem;
    padding-top: calc(var(--vertical-padding) + var(--header-height));
    padding-bottom: calc(var(--vertical-padding));
}

.hero-section-background
{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.hero-section-image-shadow {
    filter: drop-shadow(0px 0px 10px #f2d156);
}

/* Section anchor */
.hero-section-anchor {
    position: absolute;
    transform: translate(0rem, 0rem);
}

/* Section content */
.hero-section-content
{
    position: relative;
    width: 100%;

    background-color: black;
}

/* Title block */
.hero-section-title-block-container {
    position: relative;
    top: 2em;

    /*background-color: deeppink;*/
}
@media (min-width: 768px) {
    .hero-section-title-block-container {
        left: 10%;
        width: 60%;
        max-width: 600px;
        min-width: 500px;
    }
}
@media (max-width: 767px) {
    .hero-section-title-block-container {
        margin: auto;
        max-width: 500px;
        min-width: 290px;
    }
}

.hero-section-title-text {
    max-width: 300px !important;
    text-shadow: 0px 0px 10px #fff !important;
}

/* Art block */
.hero-section-art-block-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    overflow: hidden;

    pointer-events: none;
}
.hero-section-art-image-container {
    position: absolute;
    bottom: 0;
}
@media (min-width: 1300px)   {
    .hero-section-art-image-container {
        max-width: calc((1300px - 2em - 17px) * 0.75);
        right: calc((100vw - (1300px - 2em - 17px)) / 2 - 10em);
    }
}
@media (max-width: 1299px) and (min-width: 850px)   {
    .hero-section-art-image-container {
        max-width: calc((100vw - 2em) * 0.75);
        right: calc(1em - 10em);
    }
}
@media (max-width: 849px) and (min-width: 590px) {
    .hero-section-art-image-container {
        max-width: calc(615px);
        right: calc(1em - 10em);
    }
}
@media (max-width: 589px) {
    .hero-section-art-image-container {
        max-width: calc(615px);
        --shift: 6.5em;
        left: calc(var(--shift));
        right: calc(-1 * var(--shift) - var(--shift) / 2);
    }
}