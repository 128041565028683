/* General */
.reviews-section {
    position: relative;

    background-color: #fdf8f3;
}

.reviews-section-padding {
    --vertical-padding: 2rem;
    padding-top: calc(var(--vertical-padding));
    padding-bottom: calc(var(--vertical-padding));
}

/* Section anchor */
.reviews-section-anchor {
    position: absolute;
    transform: translate(0rem, 0rem);
}

/* Section content */
.reviews-section-content {
    position: relative;
    width: 100%;
}
@media (min-width: 768px) {
    .reviews-section-content {
        height: 780px;
    }
}
@media (max-width: 767px) {
    .reviews-section-content {
        height: 560px;
    }
}

/* Section content: text */
.reviews-section-content-text {
    position: relative;
    height: 100% !important;
}