/* This a single styling source for typography on the website */

/* Typography */

.app-typography {
    /* Just to hook up this CSS file in App.js and make it available throughout the application */
}

/* Font families */

body {
    font-family: 'Open Sans', sans-serif !important;
}

body input {
    font-family: 'Open Sans', sans-serif !important;
}

.ui.header {
    font-family: "Pacifico", cursive !important;
}

.ui.menu {
    font-family: 'Open Sans', sans-serif !important;
}

.ui.button {
    font-family: 'Open Sans', sans-serif !important;
}

.ui.card .header {
    font-family: 'Open Sans', sans-serif !important;
}

/* Font sizes and weights */

body p {
    font-size: 16px !important;
}

.massive-header {
    font-size: 3rem !important;
    font-weight: 900 !important;
}

.ui.header.huge {
    font-weight: 600 !important;
}

.ui.header.large {
    font-weight: 600 !important;
}

.ui.header.medium {
    font-weight: 600 !important;
}

.ui.header.small {
    font-weight: 600 !important;
}

.ui.header.tiny {
    font-weight: 600 !important;
}

.ui.menu a:not(.button) {
    font-weight: 700 !important;
}

.header-logo-text {
    font-weight: 600 !important;
}

/* Adjustments to Semantic UI spacing */

.ui.massive-header + .ui.header {
    margin-top: -0.3em !important;
}

/* Text coloring */
.red-stone-text {
    color: #bc3801 !important;
}

.curry-text {
    color: #e3aa26 !important;
}