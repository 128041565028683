.header-bubble-wrapper {
    display: inline-block;

    --color: #f2d156;

    border-style: solid;
    border-color: var(--color);
    border-width: 3px;
    border-radius: 2rem;
}

.header-bubble {

    --color: #ffffff;
    background-color: var(--color);

    border-style: solid;
    border-color: var(--color);
    border-width: 1em;
    border-radius: 2rem;
}

.header-bubble > .ui.header {
    color: #bc3801 !important;
}