.activated-link {
    background-color: red !important;
}

.logo-text {
    padding: 0px !important;

    font-size: 1.8em;
    font-family: "Pacifico", cursive;
}
.logo-text-red {
    color: #bc3801;
}
.logo-text-curry {
    color: #e3aa26;
}

.header-action-button {
    margin-right: -1em !important;
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
}

.header-menu-background-colored {
    background-color: #fdf8f3 !important;
}

.header-menu-item {
    color: #bc3801 !important;

    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

div > a.header-menu-item {
    border-radius: 100500rem !important;
}

.sidebar-icon {
    color: #bc3801;
}

/* Have to override mobile menu z-index to make it appear in front of the sidebar and overlay */
.header-mobile-z-index {
    z-index: 103 !important;
}

.header-mobile-sidebar {
    pointer-events: none;
    box-shadow: none !important;
}

.header-mobile-sidebar-content {
    padding: 1em;
    padding-top: 5em;

    pointer-events: auto;

    text-align: center;
}

/* Overlay displayed when the sidebar is open */
.header-mobile-sidebar-overlay {
    position: fixed;
    height: 300vh;
    width: 100vw;
    z-index: 100;

    pointer-events: auto;

    background-color: #fdeed966 !important;
    backdrop-filter: blur(6px);
}

/* For disabling body scroll. */
.header-mobile-sidebar-body-scroll-disabled {
    overflow: hidden;
    /*padding-right: 17px; !* This accounts for scrollbar, but will introduce excess padding on pages where scroll bar is not visible. TODO: Find a more flexible solution. *!*/
}

.header-mobile-menu-rounded {
    border-radius: 1.3em !important;
    background-color: #fdf8f3 !important;
}