.responsive-container {
    box-sizing: border-box;
    display: block;
    height: 100%;
}

.ui.menu > .responsive-container {
    display: flex;
}

/* For 1200px+ screens, width is equal to (1200 - (14 * (1 * 2)) - 17 = 1155); two 1em gutters and 17px scrollbar */
@media (min-width: 1300px)   {
    .responsive-container {
        width: calc(1300px - 2em - 17px);
        margin-left: auto;
        margin-right: auto;
    }
}

/* For 1199px- screens, take up whole width minus 2 1em gutters */
@media (max-width: 1299px) {
    .responsive-container {
        flex: 1;
        width: auto;
        margin-left: 1em;
        margin-right: 1em;
    }

    .ui.menu > .responsive-container {
        flex: 1;
    }
}