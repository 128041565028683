.review-card {
    --color: #f2d156;

    border-style: solid !important;
    border-width: 1px !important;
    border-color: var(--color) !important;
    border-radius: 2rem !important;

    box-shadow: 0 0 0 white !important;
}

.review-card-image {
    border-radius: 100500rem !important;
}

.review-card .ui.divider {
    background-color: #f2d156 !important;
    border-width: 0 !important;
    height: 1px !important;
}

.review-card .header {
    padding-top: 1rem;
}

.review-card-message {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}