/* Global app styling */

html {
    font-size: 14px;
}

.app-root-canvas {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.app-content-canvas {
    flex: 1;

    background-color: #fdf8f3;
}

.paragraph-block {
    --color: #f2d156;

    border-style: solid !important;
    border-width: 1px !important;
    border-color: var(--color) !important;
    border-radius: 2rem !important;

    box-shadow: 0 0 0 white;
}
.paragraph-block > p {
    /*color: #8a2900 !important;*/
}

.scroll-anchor {
    position: absolute;
    transform: translate(0em, -4em);
}

.golden-shadow-highlight {
    filter: drop-shadow(0px 0px 0px #f2d156);
}

@media (min-width: 768px) {
    .thin-horizontal-divider {
        margin-top: 0 !important;
    }
}



/* Palette (for reference) */
.website-palette {
    /*color: #fdf8f3; !* light beige *!*/
    /*color: #fdeed9; !* dark beige *!*/
    /*color: #bc3801; !* red stone *!*/
    /*color: #e3aa26; !* curry *!*/
    /*color: #f2d156; !* gold *!*/
    color: #ffffff; /* white */
    /* more colors to be added... */
}



/* Global Semantic UI elements theming */

.ui.button {
    border-radius: 100500rem;
}

.ui.button.primary {
    background-color: #bc3801;
}
.ui.button.primary:hover {
    background-color: #d74a0f !important;
}
.ui.button.primary:active {
    background-color: #8a2900 !important;
}
.ui.button.primary:focus {
    background-color: #8a2900 !important;
}

.ui.button.secondary {
    background-color: #e3aa26;
}
.ui.button.secondary:hover {
    background-color: #eabd56 !important;
}
.ui.button.secondary:active {
    background-color: #c1901f !important;
}
.ui.button.secondary:focus {
    background-color: #c1901f !important;
}

/* Removes excessive padding from grid cells.
   Source: https://stackoverflow.com/questions/43612423/remove-padding-between-stackable-grids-in-semantic-ui */
@media only screen and (max-width: 767px) {
    /*.no-excess-padding-grid.ui.stackable.grid > .column:not(.row) {*/
    /*    padding: 3px !important;*/

    /*    background-color: cyan !important;*/
    /*}*/

    .extra-top-padding-grid {
        padding-top: 1rem !important;
    }

    .no-excess-padding-grid.ui.stackable.grid > .row > .column {
        padding-top: 0rem !important;
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }
}

@media only screen and (min-width: 768px) {
    /*.testo.ui.stackable.grid > .column:not(.row) {*/
    /*    padding: 3px !important;*/

    /*    background-color: cyan !important;*/
    /*}*/

    .no-excess-padding-grid.ui.stackable.grid > .row {
        padding-top: 0rem !important;
    }

    .no-excess-padding-grid.ui.stackable.grid > .row > .column:last-child {
        padding-left: 0rem !important;
    }

    .extra-top-padding-grid {
        padding-top: 1rem !important;
    }
}