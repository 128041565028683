/* General */
.qr-section {
    position: relative;

    background-color: #fdeed9 !important;
}

.qr-section-padding {
    --vertical-padding: 2rem;
    padding-top: calc(var(--vertical-padding));
    padding-bottom: calc(var(--vertical-padding) / 2);
}

/* Section anchor */
.qr-section-anchor {
    position: absolute;
    transform: translate(0rem, 0rem);
}

/* Section content */
.qr-section-content {

}

/* Section content: text */
.qr-section-content-text {

}

/* Section content: qr image */
.qr-section-content-qr-image-wrapper {
    height: 100% !important;
}
.qr-section-content-qr-image {
    position: relative;
    margin: auto;

    border-style: solid;
    border-width: 3px;
    border-color: #f2d156;
    border-radius: 1em;
}
@media (min-width: 768px) {
    .qr-section-content-qr-image {

    }
}
@media (max-width: 767px) {
    .qr-section-content-qr-image {
        min-width: 250px !important;
    }
}
.qr-section-content-qr-image {
    filter: drop-shadow(0px 0px 10px #f2d156);
}