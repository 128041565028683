/* General */
.about-section {
    position: relative;

    background-color: #fdf8f3 !important;
}

.about-section-padding {
    --vertical-padding: 3rem;
    padding-top: calc(var(--vertical-padding));
    padding-bottom: calc(var(--vertical-padding));
}

/* Section anchor */
.about-section-anchor {
    position: absolute;
    transform: translate(0rem, 0rem);
}

/* Section content */
.about-section-content {

}

/* Section content: text */
.about-section-content-text {

}

/* Section content: qr image */
.about-section-content-author-image-wrapper {
    height: 100% !important;
}
.about-section-content-author-image {
    position: relative;
    margin: auto;
}