.order-form {
    max-width: 600px !important;
    margin: auto !important;
    margin-top: 5rem !important;
}

.order-form-success-message {
    text-align: center;
    color: #bc3801 !important;
}

.order-form-header {
    text-align: center;
    color: #bc3801 !important;
}

.field label {
    color: #bc3801 !important;
}

.field .ui.input input {
    --color: #f2d156;

    border-style: solid !important;
    border-width: 1px !important;
    border-color: var(--color) !important;
    border-radius: 2rem !important;

    box-shadow: 0 0 0 white;
}

.field textarea {
    --color: #f2d156;

    border-style: solid !important;
    border-width: 1px !important;
    border-color: var(--color) !important;
    border-radius: 2rem !important;

    /*height: 30px !important;*/

    box-shadow: 0 0 0 white;
}